import { memo } from 'react';
import { cx } from '@emotion/css';
import { Icon, IconName } from '@frontend/icons';
import { NakedButton } from '../../../../naked';
import { useStyles } from '../../../../use-styles';
import { Text } from '../../../text';
import { useRadioContext } from '../../providers';

type Props = {
  title: React.ReactNode;
  description?: React.ReactNode;
  value: string;
  trackingId?: string;
  className?: string;
  iconName?: IconName;
};

export const RadioCardOption = memo(({ title, description, trackingId, value, className, iconName }: Props) => {
  const { getOptionProps } = useRadioContext();
  const { active, checked, onChange, ...optProps } = getOptionProps(value);
  const radioStyles = useStyles('Radio', { active, checked, disabled: optProps.disabled });
  const radioOptionStyles = useStyles('RadioCard');
  const radioOptionContentContainerStyles = useStyles('RadioCardContentContainer');
  const radioOptionContentStyles = useStyles('RadioCardContent');
  const radioCheckboxStyles = useStyles('RadioCheckbox');
  const radioIconStyles = useStyles('RadioIcon');

  return (
    <NakedButton
      css={radioOptionStyles}
      {...optProps}
      className={cx(optProps.className, className)}
      onClick={onChange}
      type='button'
      trackingId={trackingId}
    >
      <div css={[radioStyles, radioCheckboxStyles]} />
      <div css={radioOptionContentContainerStyles}>
        <div css={[radioOptionContentStyles]}>
          <Text as='span' size='medium' weight='bold'>
            {title}
          </Text>
          {description && (
            <Text as='span' size='small' color='subdued'>
              {description}
            </Text>
          )}
        </div>
        {iconName && <Icon name={iconName} className='button-icon' color='default' css={radioIconStyles} />}
      </div>
    </NakedButton>
  );
});
RadioCardOption.displayName = 'RadioCardOption';
